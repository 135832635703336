export enum ActionType {
    
    getAllClients,
    getAllCampaigns,
    getAllProducts,
    addClientToSelectedClients,
    unselectAllClients,
    updateSelectedClients,
    removeClient,
    getSelectedCampaigns,
    getSelectedProducts,
    updateCampaignsToDisplay,
    updateProductsToDisplay,
    updateClientsToDisplay,
    changeDisplayForPopUp,
    changeDisplayForProductsPopUp,
    changeDisplayForLinkPopUp,
    changeDisplayForMobileMenu,
    removeCampaignByClientId,
    filterDisplayByClientId,
    resetFiltering,
    loginEditingMode,
    logoutEditingMode,
    loginWatchingMode,
    logoutWatchingMode,
    getUuid,
    saveReport,
    changeDisplayForRestoreStatePopUp,
    getDatesRanges,
    changeDisplayForReportsPopUp,
    changeDisplayForReportsLinkPopUp,
    getReportToCopy
}
